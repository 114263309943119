// Titulo
// ==========================================================================
// (function () {
//   try {
//     if (!isInPage(".el"))
//       return false;
//   } catch (error) {
//     console.log(error.name + ": " + error.message);
//   }
// }());

// https://developer.mozilla.org/en-US/docs/Web/API/Node/contains
function isInPage(element) {
  var node = document.querySelector(element);
  return (node === document.body) ? false : document.body.contains(node);
}

function isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

$(document).ready(function () {
  
  // Filtro dos itens de bula
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#bularioGeral"))
      return false;
      
      var options = {
        valueNames: ['nome-bula']
      };

      // Init list
      var lista = new List('bularioGeral', options);
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
    
    try {
      if (!isInPage("#bularioProfissional"))
      return false;
      
      var options = {
        valueNames: ['nome-bula']
      };

      // Init list
      var lista = new List('bularioProfissional', options);
    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

});